<template>
  <div class="links-wrapper">
    <n-collapse default-expanded-names="0" accordion>
      <n-collapse-item title="废弃" name="1" disabled>
        <div
          v-for="group in $config.groups"
          v-bind:key="`link-group-${group.name}`"
          class="section"
        >
          <div class="group">
            <div
              v-for="short in group.shorts"
              v-bind:key="`link-${short.title}`"
              class="item"
            >
              <div class="contentItem">
                <a :href="short.public" target="_blank">
                  <img :src="short.image" />
                </a>
                <span class="title">{{ short.title }}</span>
              </div>
            </div>
          </div>
          <hr style="visibility: hidden" />
        </div>
      </n-collapse-item>
      <n-collapse-item title="new" name="2">
        <div
          v-for="newgroup in $config.newgroup"
          v-bind:key="`link-group-${newgroup.name}`"
          class="section"
        >
          <div class="group">
            <div
              v-for="short in newgroup.shorts"
              v-bind:key="`link-${short.title}`"
              class="item"
            >
              <div class="contentItem">
                <a :href="short.public" target="_blank">
                  <img :src="short.image" />
                </a>
                <span class="title">{{ short.title }}</span>
              </div>
            </div>
          </div>
          <hr style="visibility: hidden" />
        </div>
      </n-collapse-item>
      <n-collapse-item title="aaca.eu.org" name="3">
        <div
          v-for="newgroup in $config.newgroup"
          v-bind:key="`link-group-${newgroup.name}`"
          class="section"
        >
          <div class="group">
            <div
              v-for="short in newgroup.aaca"
              v-bind:key="`link-${short.title}`"
              class="item"
            >
              <div class="contentItem">
                <a :href="short.public" target="_blank">
                  <img :src="short.image" />
                </a>
                <span class="title">{{ short.title }}</span>
              </div>
            </div>
          </div>
          <hr style="visibility: hidden" />
        </div>
      </n-collapse-item>
      <n-collapse-item title="stevessr.eu.org" name="4">
        <div
          v-for="newgroup in $config.newgroup"
          v-bind:key="`link-group-${newgroup.name}`"
          class="section"
        >
          <div class="group">
            <div
              v-for="short in newgroup.stevessr"
              v-bind:key="`link-${short.title}`"
              class="item"
            >
              <div class="contentItem">
                <a :href="short.public" target="_blank">
                  <img :src="short.image" />
                </a>
                <span class="title">{{ short.title }}</span>
              </div>
            </div>
          </div>
          <hr style="visibility: hidden" />
        </div>
      </n-collapse-item>
      <n-collapse-item title="rdfzsz.eu.org" name="5">
        <div
          v-for="newgroup in $config.newgroup"
          v-bind:key="`link-group-${newgroup.name}`"
          class="section"
        >
          <div class="group">
            <div
              v-for="short in newgroup.rdfzsz"
              v-bind:key="`link-${short.title}`"
              class="item"
            >
              <div class="contentItem">
                <a :href="short.public" target="_blank">
                  <img :src="short.image" />
                </a>
                <span class="title">{{ short.title }}</span>
              </div>
            </div>
          </div>
          <hr style="visibility: hidden" />
        </div>
      </n-collapse-item>
    </n-collapse>
  </div>
</template>

<script>
export default {
  name: "ShotsItem",
  components: {},
  data() {
    return {};
  },
  computed: {},
};
</script>

<style lang="stylus">
@require '../styles/mixins.styl'
@require '../styles/mode.styl'

.links-wrapper
  z-index 10
  padding 0.5rem 1.5rem
  text-align center

  width 100%
  max-width 1024px
  min-width 500px
  margin 0 auto
  h2, span, .desc
    gungnir-font()
  h2
    font-weight 400
    font-size 18px
    border-left 3px solid var(--accent-color)
    padding-left 10px
    margin-left 5px
    margin-top 50px
  .group
    padding-top: 5%;
    width 100%
    height 2000%
    list-style none
    display grid
    grid-auto-flow row
    grid-template-columns repeat(auto-fit, minmax(150px, 1fr))
    .item
      margin 4px
      .contentItem
        border 1px solid var(--border-color)
        border-radius 10px
        transition(all ease .3s)

        overflow hidden
        a
          text-decoration none
          img
            cursor pointer
            max-width 100%
            box-shadow inset 0 0 10px var(--text-color)
            width 128px
            height 128px
            &:hover
              animation: shake .5s alternate
              @keyframes shake
                0%,100%
                  -webkit-transform: rotate(0);
                  -moz-transform: rotate(0);
                  transform: rotate(0);
                  -ms-transform: rotate(0)
                25%
                  -webkit-transform: rotate(15deg);
                  -moz-transform: rotate(15deg);
                  transform: rotate(15deg);
                  -ms-transform: rotate(15deg)
                50%
                  -webkit-transform: rotate(-15deg);
                  -moz-transform: rotate(-15deg);
                  transform: rotate(-15deg);
                  -ms-transform: rotate(-15deg)
                75%
                  -webkit-transform: rotate(5deg);
                  -moz-transform: rotate(5deg);
                  transform: rotate(5deg);
                  -ms-transform: rotate(5deg)
                85%
                  -webkit-transform: rotate(-5deg);
                  -moz-transform: rotate(-5deg);
                  transform: rotate(-5deg);
                  -ms-transform: rotate(-5deg)
        span.title
          cursor default
          color var(--accent-color)
          padding 5px 0 10px
          display block
          overflow hidden
          text-overflow ellipsis
          -o-text-overflow ellipsis
          white-space nowrap
          color white
        .desc
          color var(--text-color-sub)
          font-size 13px
          padding 10px 0
          border-top 1px dashed #ddd
          text-overflow ellipsis
          overflow hidden
          line-height 15px
          box-shadow var(--box-shadow-hover)

@media (max-width $MQLarge)
  .links-wrapper
    width 85%

@media (max-width $MQIpad)
  .links-wrapper
    width 85%
    .group
      grid-template-columns repeat(2, 49.5%)

@media (max-width $MQMobileNarrow)
  .links-wrapper
    width 100%
    h2
      margin-left 17px
    .group
      grid-template-columns repeat(1, 100%)
      .item
        margin-left 15px
        margin-right 15px
</style>

<style src="../styles/theme.styl" lang="stylus"></style>
