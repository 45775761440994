import { createApp } from 'vue';
import App from './App.vue';
import Router from './router';
import { loadFull } from "tsparticles";
import Particles from "@tsparticles/vue3";

const app = createApp(App);

// 初始化 tsparticles
app.use(Particles, async (engine: any) => {
    await loadFull(engine); // 确保正确加载所有功能
});

app.use(Router);

fetch("/config/config.json")
    .then((response) => response.json())
    .then((config: Record<string, any>) => {
        app.config.globalProperties.$config = config;
        app.mount("#app");
        console.log(config);
    })
    .catch((error: Error) => console.error("Get config.json error:", error));
