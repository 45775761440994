<template>
  <vue-particles
    class="main-background"
    id="tsparticles"
    :options="particlesOptions"
    :style="{
      background: `${
        $config.backgrounds[
          Math.floor(Math.random() * $config.backgrounds.length)
        ]
      } no-repeat fixed`,
      'background-size': `100% 100%`,
      'background-size': 'cover', // Changed this line
    }"
    color="#dedede"
    :particleOpacity="0.5"
    :particlesNumber="20"
    :particleSize="15"
    shapeType="circle"
    linesColor="#cfcfcf"
    :linesWidth="1"
    :lineLinked="true"
    :lineOpacity="0.26"
    :linesDistance="650"
    :moveSpeed="2"
    :hoverEffect="false"
    hoverMode="grab"
    :clickEffect="false"
    clickMode="push"
  ></vue-particles>
  <div class="mainItem" :style="{ background: $config.mask }">
    <NavbarItem />
    <ContentItem />
    <FooterItem />
  </div>
</template>

<script>
import NavbarItem from "../components/Navbar.vue";
import ContentItem from "../components/Content.vue";
import FooterItem from "../components/Footer";

export default {
  name: "MainItem",
  props: {},
  components: {
    NavbarItem,
    ContentItem,
    FooterItem,
  },
  data() {
    return {
      particlesOptions: {
        background: {
          color: {
            value: "#000",
          },
        },
        particles: {
          number: {
            value: 50,
          },
          color: {
            value: "#ffffff",
          },
          shape: {
            type: "circle",
          },
          opacity: {
            value: 0.5,
          },
          size: {
            value: 3,
          },
          move: {
            enable: true,
            speed: 2,
          },
        },
      },
    };
  },
};
</script>

<style lang="stylus" scoped>
@require '../styles/mixins.styl'
@require '../styles/mode.styl'

.main-background
    position fixed
    top 0
    z-index 0
    width 100%
    height 100%

.mainItem
    z-index 0
</style>

<style>
.analysis {
  width: 100%;
  height: calc(100%);
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
